
.home {
    position: relative;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    &__text-wrapper {
        display: flex;
        justify-content: center;

        h1 {
            color: var(--cyan-theme-main-color);
            margin: 0px;
            font-weight: 500;
            font-size: 8rem;
            line-height: 10rem;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            animation: typingEffectAnimation 1s steps(60, end);
        }
    }

    &__contact-me {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            display: inline-block;
            background-color: var(--cyan-theme-main-color);
            color: var(--cyan-theme-sub-text-color);
            padding: 10px 25px;
            border-radius: 2px;
            border: none;
            margin-top: 1.5cm;
            transition: all 0.3s ease;
        }

        button:hover {
            background-color: var(--cyan-theme-background-color);
            cursor: pointer;
            transform: translateY(-5px);
        }

        .rocket-button {
            background: transparent;
            border: 1px solid var(--cyan-theme-main-color);
            color: var(--cyan-theme-main-color);
            padding: 10px 80px;
            font-size: 18px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.3s ease;
        }

        .rocket-button span {
            position: relative;
            z-index: 1;
        }

        .rocket-button i {
            position: absolute;
            top: -50px;
            left: -50px;
            opacity: 0;
            transform: rotate(45deg);
            transition: all 0.5s ease;
        }

        .rocket-button:hover {
            color: var(--cyan-theme-background-color);
            background: var(--cyan-theme-main-color);
            box-shadow: 0 10px 100px rgba(#f05454, 0.5);
        }

        .rocket-button:hover i {
            top: -20px;
            left: -20px;
            opacity: 0.5;
        }
    }
}


@media only screen and (max-width : 550px) {

    .home__text-wrapper h1 {
        font-size: 3rem;
        line-height: 5rem;
    }

}

@media only screen and (min-width : 551px) and (max-width : 760px) {

    .home__text-wrapper h1 {
        font-size: 5rem;
        line-height: 6rem;
    }

}

@media only screen and (min-width : 760px) and (max-width : 990px) {

    .home__text-wrapper h1 {
        font-size: 6rem;
        line-height: 8rem;
    }

}

@keyframes typingEffectAnimation {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

