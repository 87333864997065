.about {
    &__content {
        display: flex;
        gap: 20px;
        padding: 20px;
        width: 100%;
        flex-wrap: wrap;

        &__personalWrapper,
        &__servicesWrapper {
            width: 50%;
        }

        &__personalWrapper {
            h3 {
                margin-bottom: 10px;
                font-size: 24px;
                font-weight: 700;
                color: var(--cyan-theme-main-color);
            }

            h3.personalInfo {
                padding-top: 20px;
                font-size: 28px;
            }

            p {
                color: var(--cyan-theme-sub-text-color);
                font-size: 15px;
                line-height: 1.5;
                padding-bottom: 20px;
                border-bottom: 1px dashed var(--cyan-theme-main-color);
                font-weight: normal;
            }

            ul>li {
                line-height: 1rem;
                margin-bottom: 15px;

                span.title,
                span.value {
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    border-bottom: 1px solid #BB371A;
                    position: relative;
                    display: inline-block;
                    margin-right: 16px;
                    padding-bottom: 1px;
                    color: var(--cyan-theme-sub-text-color);
                }

                span.value {
                    border: none;
                }

            }
        }

        &__servicesWrapper {

            &__innerContent {
                width: 300px;
                height: 300px;
                background: radial-gradient(circle at 30% 30%, #FFF8D9 3%, #D5DBB3 30%, #EBA83A 45%, #BB371A 60%, #0000 80%);
                position: relative;
                left: 30%;
                top: 100%;
                border-radius: 50%;
                box-shadow: 0px 0px 30px rgba(#d43e1c, 1);

                &__borderPlanet {
                    width: 300px;
                    height: 300px;
                    border-radius: 50%;
                    border: 1px solid #fefefe00;
                    animation: rotation 10s linear 1s infinite;

                    div:nth-child(1) {
                        position: absolute;
                        right: -12%;
                        top: 41%;
                    }

                    div:nth-child(2) {
                        position: absolute;
                        left: 37%;
                        bottom: -11%;
                    }

                    div:nth-child(3) {
                        position: absolute;
                        left: 37%;
                        top: -11%;
                    }

                    div:nth-child(4) {
                        position: absolute;
                        right: 87%;
                        top: 41%;
                    }

                }


            }
        }
    }
}


@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width : 768px) {
    .about__content {
        flex-direction: column;

        &__personalWrapper,
        &__servicesWrapper {
            width: 100%;
        }

        &__servicesWrapper {
            margin: 10px 0px;

            &__innerContent {
                position: absolute;
                left: 9%;
                top: 100%;
                width: 250px;
                height: 250px;

                &__borderPlanet {
                    position: absolute;
                    width: 250px;
                    height: 250px;
                    left: 0%;
                    top: 0;
                }
            }
        }
    }
}