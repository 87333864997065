@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

$primary-color: #f05454;
$secondary-color: #6c757d;

.contact {
    background-color: var(--cyan-theme-background-color);
    padding: 40px;
    height: 100%;

    &__contact-me {

        h3 {
            font-family: 'Raleway', sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2%;
            font-size: 36px;
            color: #f05454;
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 10px;
            letter-spacing: 2px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        &__header-text {
            font-family: "Roboto", sans-serif;
            font-size: 24px;
            font-weight: 700;
            color: $primary-color;
            margin-bottom: 20px;
        }

        &__form {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__controlswrapper {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                div {
                    position: relative;
                    margin-bottom: 15px;

                    input,
                    textarea {
                        border: none;
                        background-color: transparent;
                        font-family: "Roboto", sans-serif;
                        font-size: 16px;
                        width: 300px;
                        padding: 10px;
                        border-bottom: 2px solid $secondary-color;
                        transition: border-bottom-color 0.3s ease;

                        &:focus {
                            outline: none;
                            border-bottom-color: $primary-color;
                        }
                    }

                    label {
                        position: absolute;
                        left: 10px;
                        top: 12px;
                        color: $secondary-color;
                        font-size: 16px;
                        pointer-events: none;
                        transition: all 0.3s ease;
                    }

                    input:focus+label,
                    textarea:focus+label,
                    input:valid+label,
                    textarea:valid+label {
                        top: -12px;
                        left: 0;
                        font-size: 12px;
                        color: $primary-color;
                    }
                }
            }

            button {
                background-color: $primary-color;
                color: #fff;
                border: none;
                padding: 10px 20px;
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: darken($primary-color, 10%);
                }
            }
        }
    }

    &__social-icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
        justify-items: center;
        grid-gap: 20px;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .top-icons a:nth-child(3n+1),
        .bottom-icons a:nth-child(3n+1) {
            grid-column: 1;
        }

        .top-icons a:nth-child(3n+2),
        .bottom-icons a:nth-child(3n+2) {
            grid-column: 2;
        }

        .top-icons a:nth-child(3n+3),
        .bottom-icons a:nth-child(3n+3) {
            grid-column: 3;
        }

        h3 {
            font-size: 3rem;
            margin-left: 20px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-color;
            font-size: 36px;
            margin-right: 10px;
            cursor: pointer;
            transition: color 0.3s ease;

            &:hover {
                color: darken($primary-color, 10%);
            }
        }
    }
}